import Chart from "chart.js/auto";

(function () {
  function calculateGrowth(
    initialValue: number,
    years: number,
    annualGrowthRate: number,
    capitalGainsTaxRate = 0
  ): { year: number; value: number }[] {
    let value = initialValue;
    const data = [{ year: 2024, value }];

    for (let year = 2025; year <= 2024 + years; year++) {
      if (year === 2029) {
        value = 100000000; // $100M exit after 5 years
      } else if (year > 2029) {
        const growth = value * annualGrowthRate;
        const taxableGrowth = growth * (1 - capitalGainsTaxRate);
        value += taxableGrowth;
      }
      data.push({ year, value });
    }

    return data;
  }

  function combineData(
    dataWithTrust: { year: number; value: number }[],
    dataWithoutTrust: { year: number; value: number }[]
  ): { year: number; withTrust: number; withoutTrust: number }[] {
    return dataWithTrust.map((item, index) => ({
      year: item.year,
      withTrust: item.value,
      withoutTrust: dataWithoutTrust[index].value,
    }));
  }

  function formatCurrency(value: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  function renderWealthGrowthChart(): void {
    const yearsToProject = 56;
    const annualGrowthRate = 0.1;
    const capitalGainsTaxRate = 0.37;

    const dataWithTrust = calculateGrowth(
      5000,
      yearsToProject,
      annualGrowthRate
    );
    const dataWithoutTrust = calculateGrowth(
      5000,
      yearsToProject,
      annualGrowthRate,
      capitalGainsTaxRate
    );
    const combinedData = combineData(dataWithTrust, dataWithoutTrust);

    const finalWithTrust = dataWithTrust[dataWithTrust.length - 1].value;
    const finalWithoutTrust =
      dataWithoutTrust[dataWithoutTrust.length - 1].value;
    const difference = finalWithTrust - finalWithoutTrust;

    const withTrustElement = document.querySelector(
      ".with-trust"
    ) as HTMLElement;
    const withoutTrustElement = document.querySelector(
      ".without-trust"
    ) as HTMLElement;

    if (withTrustElement && withoutTrustElement) {
      withTrustElement.innerHTML = `${formatCurrency(
        finalWithTrust
      )} with trust <span class="difference">${formatCurrency(
        difference
      )} higher</span>`;
      withoutTrustElement.textContent = `${formatCurrency(
        finalWithoutTrust
      )} without trust`;
    }

    const labels = combinedData.map((item) => item.year.toString());
    const withTrustValues = combinedData.map((item) => item.withTrust);
    const withoutTrustValues = combinedData.map((item) => item.withoutTrust);

    const ctx = document.getElementById(
      "wealthGrowthChart"
    ) as HTMLCanvasElement;

    new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "With ETZ",
            data: withTrustValues,
            borderColor: "#2891FF",
            backgroundColor: "rgba(40, 145, 255, 0.2)",
            borderWidth: 2,
            fill: true,
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: "Without ETZ",
            data: withoutTrustValues,
            borderColor: "#226DFF",
            backgroundColor: "rgba(34, 109, 255, 0.2)",
            borderWidth: 2,
            fill: true,
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return "$" + (value as number) / 1e6 + "M";
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: any) {
                return formatCurrency(context.parsed.y);
              },
            },
          },
          legend: {
            display: true,
          },
        },
      },
    });
  }

  document.addEventListener("DOMContentLoaded", renderWealthGrowthChart);
})();
